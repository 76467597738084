import React from "react"
// import { RichText } from "prismic-reactjs"

const Embed = ({ slice }) => (
  <div className="post-embed container">
    {/* <div>{RichText.render(slice.primary.embed_link.raw)}</div> */}
    <div dangerouslySetInnerHTML={{ __html: slice.primary.embed_link.html }} />
  </div>
)

export default Embed
