import React from "react"
import "../../../styles/slices/quote.css"

const Quote = ({ slice }) => (
  <div className="post-quote container">
    <blockquote>{slice.primary.quote.text}</blockquote>
  </div>
)

export default Quote
