import React from "react"
import { RichText } from "prismic-reactjs"

const Text = ({ slice }) => (
  <div className="post-text container">
    <div>{RichText.render(slice.primary.paragraph.raw)}</div>
  </div>
)

export default Text
